import React from 'react';

import {
    useNavigate
} from 'react-router-dom';

import FormField from '../../utils/form/formField';
import { FiSearch } from 'react-icons/fi';
import { FaHome } from 'react-icons/fa';
// import AdminLoading from '../../utils/circle.loading';

const FolderTemplate = ({
    tableId,
    tableName,
    alldata,
    loading,
    theTheme,
    pageSize,
    navigateto,
    isMobile,
    isTablet,
    isBiggerTablet,
    isBigPC,
    singleImage = false
}) => {

    const navigate = useNavigate();

    const [currentPage, currentPageHandler] = React.useState(1);
    const [dataCount, dataCountHandler] = React.useState("");
    const [mydata, mydataHandler] = React.useState([]);

    const [filterData, filterDataHandler] = React.useState({
        filterText: {
            element: 'input',
            value: '',
            config: {
                name: 'Filter',
                type: 'text',
                placeholder: 'Search here'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    React.useEffect(() => {
        let mounted = true;
        const implementData = async () => {
            if (mounted) {
                if (alldata && alldata.length > 0) {
                    mydataHandler(alldata);
                }
            }
        }
        implementData();
        return () => {
            mounted = false;
        }
    }, [alldata])

    const gotoDetails = React.useCallback((data) => {
        navigate(`/private/${navigateto}/${data._id}`)
    }, [navigate])

    const gotoDetailsRef = React.useRef(gotoDetails);

    React.useEffect(() => {
        gotoDetailsRef.current = gotoDetails;
    }, [gotoDetails]);

    const ThisFolder = React.useCallback((item, index) => {

        // const exampleImages = [
        //     {
        //         secureurl: "https://res.cloudinary.com/drw2jicib/image/upload/v1704562262/c226d5ef4080a332372a70502_paqule.jpg",
        //         alternative: '1'
        //     },
        //     {
        //         secureurl: "https://res.cloudinary.com/drw2jicib/image/upload/v1704547272/c226d5ef4080a332372a70501_ngo3iu.jpg",
        //         alternative: '2'
        //     },
        //     {
        //         secureurl: "https://res.cloudinary.com/drw2jicib/image/upload/v1704562262/c226d5ef4080a332372a70502_paqule.jpg",
        //         alternative: '3'
        //     },
        //     {
        //         secureurl: "https://res.cloudinary.com/drw2jicib/image/upload/v1704562262/c226d5ef4080a332372a70502_paqule.jpg",
        //         alternative: '4'
        //     },
        //     {
        //         secureurl: "https://res.cloudinary.com/drw2jicib/image/upload/v1704547272/c226d5ef4080a332372a70501_ngo3iu.jpg",
        //         alternative: '5'
        //     },
        //     {
        //         secureurl: "https://res.cloudinary.com/drw2jicib/image/upload/v1704547272/c226d5ef4080a332372a70501_ngo3iu.jpg",
        //         alternative: '6'
        //     },
        //     {
        //         secureurl: "https://res.cloudinary.com/drw2jicib/image/upload/v1704562262/c226d5ef4080a332372a70502_paqule.jpg",
        //         alternative: '7'
        //     },
        //     {
        //         secureurl: "https://res.cloudinary.com/drw2jicib/image/upload/v1704562262/c226d5ef4080a332372a70502_paqule.jpg",
        //         alternative: '8'
        //     }
        // ]

        const showImages = (data) => {
            // let data = exampleImages
            const newArray = [];
            for (let i = 0; i < 9; i++) {
                newArray.push(new Object({
                    secureurl: "",
                    alternative: ""
                }))
            }
            for (let j = 0; j < newArray.length; j++) {
                for (let z = 0; z < data.length; z++) {
                    if (data[j] === undefined) {
                        newArray[j].secureurl = "url"
                        newArray[j].alternative = 'alternative'
                    } else {
                        newArray[j].secureurl = data[j].secureurl
                        newArray[j].alternative = data[j].alternative
                    }
                }
            }

            return (
                <div className='galleryThumbnails'>
                    {
                        singleImage ?
                            <div
                                style={{
                                    width: '100%',
                                    display: 'block'
                                }}
                            >
                                <img
                                    style={{
                                        width: '100%'
                                    }}
                                    src={newArray[0].secureurl}
                                    alt={newArray[0].alternative}
                                />
                            </div>
                            :
                            newArray.map((single, ind) => {
                                if (single.secureurl === 'url') {
                                    return (
                                        <div
                                            key={ind}
                                            className='singleImage'
                                        >&nbsp;</div>
                                    )
                                } else {
                                    return (
                                        <div
                                            key={ind}
                                            className='singleImage'
                                        >
                                            <img
                                                src={single.secureurl}
                                                alt={single.alternative}
                                            />
                                        </div>
                                    )
                                }
                            })
                    }
                </div>
            )
        }

        let totalText = isMobile ? 18 : isTablet ? 18 : isBiggerTablet ? 18 : isBigPC ? 18 : 20;

        function fn(text, count) {
            return text.slice(0, count) + (text.length > count ? "..." : "");
        }

        return (
            <div
                key={index}
                className='lumi-md-2 lumi-xs-12 galleryCardWrapper'
                onClick={() => gotoDetailsRef.current(item)}
            >
                <div className='galleryCard'>
                    {
                        item.show ?
                            <span><FaHome /></span> : null
                    }
                    {
                        item.images && item.images.length > 0 ?
                            showImages(item.images)
                            : null
                    }
                    <div className='galleryText'>
                        {fn(item.name, totalText)}
                    </div>
                </div>
            </div>
        );
    }, [])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            var results = [];
            var resultsdata = [];
            var offset = (currentPage - 1) * pageSize;

            const filterObject = (obj, theText) => {
                return Object.values(obj).some((value) => {
                    if (typeof value === 'object') {
                        return filterObject(value, theText); // Recursively search nested objects
                    }
                    return value.toString().toLowerCase().includes(theText.toLowerCase());
                });
            };

            results = alldata && alldata.length > 0 && alldata.filter(data => filterObject(data, filterData.filterText.value.toLowerCase()));
            // results = alldata && alldata.length > 0 && alldata.filter(data => Object.values(data).join(' ').toLowerCase().includes(filterData.filterText.value.toLowerCase()));
            resultsdata = results && results.length > 0 && results.map((item, index) => ThisFolder(item, index));
            var semuadata = resultsdata ? [...resultsdata] : [];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            dataCountHandler(results && results.length);
            mydataHandler(mydatas);
        }
        return () => {
            mounted = false;
        };
    }, [alldata, currentPage, filterData.filterText.value, mydataHandler, pageSize, ThisFolder])

    const updateForm = (element, formdata) => {
        const newFormdata = {
            ...formdata
        }
        const newElement = {
            ...newFormdata[element.id]
        }
        newElement.value = element.event.target.value;
        newFormdata[element.id] = newElement;
        filterDataHandler(newFormdata);
    }

    return (
        <div className='cardWrapper'>
            <div className='cardHeader'>
                <h5>{tableName}</h5>
                <div className='cardOptions'>
                    <ul>
                        <li className='inputSearch'>
                            <FormField
                                id={'filterText'}
                                formdata={filterData.filterText}
                                change={(element) => updateForm(element, filterData)}
                                theClass="inputclass"
                                icons={<FiSearch />}
                                showName={false}
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div className='cardBody'>
                <div className='row'>
                    {
                        mydata && mydata.length > 0 ? mydata :
                            <div
                                className='emptyGallery'
                            >
                                No {navigateto} found
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FolderTemplate;