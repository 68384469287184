import React, {
    useState,
    useEffect
} from 'react';

import Slider from "react-slick";

import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../../utils/windowsize';
import { mobileSize, tabletSize, isBiggerTablet } from '../data/settings';

const HomeCard = (props) => {
    // console.log(props, '<<<props')
    const navigate = useNavigate();
    const size = useWindowSize();
    const isMobile = size.width <= mobileSize.width;
    const isTablet = size.width >= tabletSize.minWidth && size.width <= tabletSize.minHeight;
    const isBigger = size.width >= isBiggerTablet.minWidth && size.width <= isBiggerTablet.minHeight;

    const [imgsLoaded, setImgsLoaded] = useState(false)

    const gotoSingleProduct = (id) => {
        navigate(`/${props.link}/${id}`, {
            state: {
                dataselected: props
            }
        })
    }

    const settings = {
        dots: false,
        arrows: true,
        swipe: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        const loadImage = image => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image()
                loadImg.src = image.secureurl;
                loadImg.alt = image.alternative;
                // wait 2 seconds to simulate loading time
                loadImg.onload = () =>
                    setTimeout(() => {
                        resolve(image.secureurl)
                    }, 1000)

                loadImg.onerror = err => reject(err)
            })
        }

        Promise.all(props.images && props.images.map(imageslider => loadImage(imageslider)))
            .then(() => setImgsLoaded(true))
            .catch(err => console.log("Failed to load images", err))
    }, [])

    // const getallimages = (variant) => {
    //     let newarray = []
    //     for (let i = 0; i < variant.length; i++) {
    //         for (let j = 0; j < variant[i].images.length; j++) {
    //             newarray.push(variant[i].images[j]);
    //         }
    //     }
    //     return newarray;
    // }

    function checkProductname(text, count, insertDots) {
        return text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "");
    }

    const maxLetter = isMobile ? 35 : 23;
    const logoUrl = require('../resources/images/LuminousSposa_Logo_1.png');
    const heightValue = isMobile ? '206px' : isTablet ? '165px' : isBigger ? '218px' : '339px';

    return (
        <div
            className="productcard"
        >
            <div
                className="productimage"
                style={{
                    minHeight: heightValue,
                    alignContent: 'center'
                }}
            >
                {
                    props.images && props.images.length > 0 ?
                        imgsLoaded ?
                            !isMobile ?
                                <Slider {...settings}>
                                    {
                                        props.images.map((newimage, index) => {
                                            return (
                                                <img
                                                    onClick={() => gotoSingleProduct(props._id)}
                                                    key={index}
                                                    src={newimage.secureurl}
                                                    alt={newimage.alternative}
                                                />
                                            )
                                        })
                                    }
                                </Slider>
                                :
                                <img
                                    onClick={() => gotoSingleProduct(props._id)}
                                    src={props.images[0].secureurl}
                                    alt={props.images[0].alternative}
                                />
                            : <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(0,0,0,0.2)',
                                height: heightValue
                            }}>
                                <img src={logoUrl} alt="" width="40px" />
                                {/* <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> */}
                            </div>
                        : null

                }
            </div>
            <div className="productdesc">
                <div className="product_title">
                    <h3>{checkProductname(props.name, maxLetter, true)}</h3>
                </div>
                {/* <div className="product_brand">{props.brand.name}</div> */}
                {/* <div className="product_category">{props.pcategory.name}</div> */}
            </div>
        </div>
    )
}

export default HomeCard;